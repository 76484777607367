import React from 'react';

import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';

import SEO from 'Components/SEO';

export default ({ data }) => {
  const { labTour: { edges: [ { node: tour } ] } } = data;

  const embedVideoId = tour.frontmatter.videoUrl.includes('&')
    ? tour.frontmatter.videoUrl.split('v=')[1].split('&')[0]
    : tour.frontmatter.videoUrl.split('v=')[1];

  return (
    <PageWrapper>
      <SEO title="tour-of-the-lab" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">{ tour.frontmatter.title }</h1>
        <div className="page__content page__content--lab-tour" dangerouslySetInnerHTML={ { __html: tour.html } } />
        <div className="page__video-wrapper">
          <div className="page__video-content">
            <iframe
              title="lab-tour"
              src={ `https://www.youtube.com/embed/${embedVideoId}` }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    labTour: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "tour_of_the_lab"},
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            videoUrl
          }
        }
      }
    }
  }
`;
